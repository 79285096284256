import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

$('#applicationForm').on('submit-success', function(event, data) {
    // do something with the response data
    console.log(data);
    alert('Pomyślnie przesłano formularz.', 'success');
});

const applicationSwiperEl = document.querySelector('.left-container__form-step-how .swiper')
if (applicationSwiperEl) {
    const applicationSwiper = new Swiper(document.querySelector('.left-container__form-step-how .swiper'), {
        slidesPerView: 1,
        loop: true,
        touchRatio: 0.1,
        navigation: {
            prevEl: document.querySelector('.left-container__form-step-how .swiper-button-prev'),
            nextEl: document.querySelector('.left-container__form-step-how .swiper-button-next')
        },
        modules: [Navigation]
    });
}
